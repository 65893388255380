






























































@import '@design';

.field:not(:last-child) {
    margin-bottom: 7px;
}
.fieldWrapper {
    display: flex;
    align-items: center;
    background: $color-background-input;
    border-radius: $border-radius;
}
.radios {
    input {
        position: relative;
        width: 18px;
        height: 18px;
        vertical-align: -4px;
        cursor: pointer;
        border: 1px solid;
        border-color: #d9d9d9;
        border-radius: 50%;
        box-shadow: 0 0 0 0 $color-link inset;
        transition: all 0.2s ease-in-out;
        &:checked + label {
            color: $color-link !important;
        }
        &:checked {
            border: none;
            box-shadow: 0 0 0 10px $color-link inset;
            &::after {
                position: absolute;
                top: 50%;
                left: 50%;
                display: block;
                width: 4px;
                height: 4px;
                margin-top: -2px;
                margin-left: -2px;
                content: '';
                background-color: #fff;
                border-radius: 50%;
                opacity: 0;
                opacity: 1;
                transition: all 0.2s ease-in-out 0.1s;
                transform: scale(1);
            }
        }
    }
}
.radioLabel {
    font-size: 0.95em;
    color: $color-text-soft;
    display: flex;
    flex: 1;
    justify-content: space-between;
    padding: 13px 15px;
}
.radioInputWrapper {
    padding: 13px 0 13px 15px;
}
.radioName {
    flex: 1;
    span {
        display: block;
        color: $color-text;
        font-weight: 600;
    }
}
.radioDetails {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 15px;
    font-family: $font-family-heading;
    font-weight: 700;
}
.label {
    font-size: 0.95em;
    color: #737373;
}
